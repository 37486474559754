<template>
  <div />
</template>
<script>
export default {
  created() {
    return this.$nuxt.error({ statusCode: 404, message: "nem letezik" });
  },
};
</script>
